<template>
  <section>
    <div class="container">
        <div class="row mb-4 pb-4">
            <div class="col-12">
                <h1 class="mb-4 pb-4">Datos Abiertos</h1>
                <p>Esta plataforma se alimenta a partir de un sistema de monitoreo social de los presupuestos y gastos en atención de VIH, para ser implementados a nivel nacional y en el ámbito de los organismos subregionales que se ocupan de la respuesta al VIH. Los datos que encontrarán en esta plataforma refleja un esfuerzo conjunto de sociedad civil, estados que aplican recomendaciones del estándar de contrataciones abiertas, así como organizaciones internacionales como Onusida. </p>
            </div>
            </div>
             <div class="row">
            <div class="col-12">
                <div class="table">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Título</th>
                            <th>Fecha de consulta</th>
                            <th>Formato</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><strong>Indicadores</strong></td>
                            <td>15/07/2021</td>
                            <td><span class="od_format"><svg-icon icon="json" :hasFill="true"></svg-icon> csv</span></td>
                            <td>
                              <a class="od_dl_btn" href="/js/data/indices.csv"><svg-icon icon="descarga" :hasFill="true"></svg-icon> Descargar</a>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Contratos</strong></td>
                            <td>15/07/2021</td>
                            <td><span class="od_format"><svg-icon icon="json" :hasFill="true"></svg-icon> csv</span></td>
                            <td>
                              <a class="od_dl_btn" href="/js/data/contracts.zip"><svg-icon icon="descarga" :hasFill="true"></svg-icon> Descargar</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        
        </div>
        </div>
        </section>
</template>